// TOOD Medium: Add webpackChunkName to group chunks?

// TODO High: Test all this magic?

function createRouteModal(route) {
	return {
		path: route.path || route.modalPath,
		meta: route.meta || undefined,
		component: () => import('./components/layout/EmptyRouterView.vue'),
		children: [
			{
				path: '',
				beforeEnter(a, b, next) {

					// Use fixed background page?
					if (route.defaultPath) {

						let defaultRoute = routes.find(route2 => route2.path == route.defaultPath && !route2.modalPath);

						a.matched[1].currentDefaultPath = route.defaultPath;
						a.matched[1].components.default = defaultRoute.children[0].component;

					}
					// Use current page, with homepage as fallback?
					else {

						// Set default component once?
						if (!a.matched[1].defaultComponentFaked)
							a.matched[1].defaultComponentFaked = a.matched[1].default;

						if (b.matched[1]) {
							a.matched[1].currentDefaultPath = b.matched?.[1]?.currentDefaultPath || b.path;
							a.matched[1].components.default = b.matched[1].defaultComponentFaked || b.matched[1].components.default;
						} else {
							a.matched[1].currentDefaultPath = '/'
							a.matched[1].components.default = () => import('./components/pages/Home.vue');
						}

					}

					// Proceed in router.
					next();
				},
				components: route.components || {
					dialog: route.component,
				},
			}
		],
	};
}

function createRoutePage(route) {
	return {
		path: route.path,
		component: () => import('./components/layout/EmptyRouterView.vue'),
		children: [
			{
				path: '',
				component: route.component,
				children: route.children || undefined,
			}
		],
		meta: route.meta || undefined,
	};
}

function createPageTitle(title) {
	return 'ecolands - ' + title;
}

let routes = [

	createRoutePage({
		path: '/',
		component: () => import('./components/pages/Home.vue'),
		meta: {
			title: createPageTitle('Home')
		}
	}),

	createRoutePage({
		path: '/licenses',
		component: () => import('./components/pages/Licenses.vue'),
		meta: {
			title: createPageTitle('Licenses')
		}
	}),

	createRoutePage({
		path: '/map',
		component: () => import('./components/pages/Map.vue'),
		meta: {
			title: createPageTitle('Map')
		}
	}),

	createRouteModal({
		defaultPath: '/map',
		modalPath: '/map/buy',
		components: {
			default: () => import('./components/pages/Map.vue'),
			dialog: () => import('./components/pages/map/buyModal/IntroStep.vue'),
		},
	}),

	// Web2
	createRouteModal({
		defaultPath: '/map',
		modalPath: '/map/buy/web2/completed',
		components: {
			default: () => import('./components/pages/Map.vue'),
			dialog: () => import('./components/pages/map/buyModal/web2/CompletedStep.vue'),
		},
	}),
	createRouteModal({
		defaultPath: '/map',
		modalPath: '/map/buy/web2/rejected',
		components: {
			default: () => import('./components/pages/Map.vue'),
			dialog: () => import('./components/pages/map/buyModal/web2/RejectedStep.vue'),
		},
	}),

	// Web3
	createRouteModal({
		defaultPath: '/map',
		modalPath: '/map/buy/web3/approval',
		components: {
			default: () => import('./components/pages/Map.vue'),
			dialog: () => import('./components/pages/map/buyModal/web3/ApprovalStep.vue'),
		},
	}),

	createRouteModal({
		defaultPath: '/map',
		modalPath: '/map/buy/web3/completed',
		components: {
			default: () => import('./components/pages/Map.vue'),
			dialog: () => import('./components/pages/map/buyModal/web3/CompletedStep.vue')
		},
	}),

	createRouteModal({
		defaultPath: '/map',
		modalPath: '/map/buy/credits',
		components: {
			default: () => import('./components/pages/Map.vue'),
			dialog: () => import('./components/pages/map/buyModal/web2/BuyCredits.vue'),
		},
		meta: {
			purchase: {
				isPurchaseWhilePurchasingDomainNftHexagons: true,
				offerType: 'profile-credits',
				purchaseApiEndpoint: '/profile/credits',
			}
		}
	}),

	createRoutePage({
		path: '/enterprise',
		component: () => import('./components/pages/Enterprise.vue'),
		meta: {
			title: createPageTitle('Enterprise')
		}
	}),

	createRoutePage({
		path: '/virtual-land',
		component: () => import('./components/pages/VirtualLand.vue'),
		meta: {
			title: createPageTitle('Virtual land')
		}
	}),

	createRoutePage({
		path: '/how-it-works',
		component: () => import('./components/pages/HowItWorks.vue'),
		meta: {
			title: createPageTitle('How it works')
		}
	}),

	createRoutePage({
		path: '/virtual-land/domains-plots',
		component: () => import('./components/pages/virtualLand/DomainsPlots.vue'),
		meta: {
			title: createPageTitle('Domains and plots')
		}
	}),

	createRoutePage({
		path: '/virtual-land/rewards',
		component: () => import('./components/pages/virtualLand/Rewards.vue'),
		meta: {
			title: createPageTitle('Rewards')
		}
	}),

	createRoutePage({
		path: '/virtual-land/strategies',
		component: () => import('./components/pages/virtualLand/Strategies.vue'),
		meta: {
			title: createPageTitle('Strategies')
		}
	}),

	createRoutePage({
		path: '/about-us',
		component: () => import('./components/pages/aboutus/AboutUs.vue'),
		meta: {
			title: createPageTitle('About us')
		}
	}),


	createRoutePage({
		path: '/team',
		component: () => import('./components/pages/aboutus/Team.vue'),
		meta: {
			title: createPageTitle('Team')
		}
	}),

	createRoutePage({
		path: '/why-ecolands',
		component: () => import('./components/pages/aboutus/WhyEcolands.vue'),
		meta: {
			title: createPageTitle('Why ecolands')
		}
	}),

	createRoutePage({
		path: '/faq',
		component: () => import('./components/pages/aboutus/Faq.vue'),
		meta: {
			title: createPageTitle('FAQ')
		}
	}),

	/*
	createRoutePage({
		path: '/roadmap',
		component: () => import('./components/pages/aboutus/Roadmap.vue'),
		meta: {
			title: createPageTitle('Roadmap')
		}
	}),
	*/

	createRoutePage({
		path: '/contact',
		component: () => import('./components/pages/aboutus/Contact.vue'),
		meta: {
			title: createPageTitle('Contact')
		}
	}),

	createRoutePage({
		path: '/pers',
		component: () => import('./components/pages/Pers.vue'),
		meta: {
			title: createPageTitle('Pers')
		}
	}),

	createRoutePage({
		path: '/map/plot/:domainNftId',
		component: () => import('./components/pages/Map.vue'),
		meta: {
			title: createPageTitle('Map')
		},
	}),

	/*{
		path: '/staking',
		component: () => import('./components/pages/Staking.vue'),
	},*/

	// createRoutePage({
	// 	path: '/carbon',
	// 	component: () => import('./components/pages/CarbonRegistry.vue'),
	// 	meta: {
	// 		title: createPageTitle('Carbon')
	// 	},
	// }),

	createRoutePage({
		path: '/certificates/:id',
		component: () => import('./components/pages/greenification/Certificate.vue'),
		meta: {
			title: createPageTitle('Certificates')
		},
	}),

	createRouteModal({
		path: '/profile/auth',
		component: () => import('./components/pages/profile/Auth.vue'),
	}),

	createRouteModal({
		path: '/profile/auth/login',
		component: () => import('./components/pages/profile/auth/web2/Login.vue'),
	}),

	// Used to redirect our invite link to the register form
	{
		path: '/invite/:referralCode',
		redirect: to => {
			return { path: '/profile/auth/register', query: { referral: to.params.referralCode } }
		},
	},

	createRouteModal({
		path: '/profile/auth/register',
		component: () => import('./components/pages/profile/auth/web2/Register.vue'),
		meta: {
			modal: {
				heightClass: 'md:h-[675px]',
			}
		}
	}),

	createRouteModal({
		path: '/profile/auth/verify-email',
		component: () => import('./components/pages/profile/auth/web2/VerifyEmail.vue'),
	}),
	createRouteModal({
		path: '/profile/auth/verify-email-confirm',
		component: () => import('./components/pages/profile/auth/web2/VerifyEmailConfirm.vue'),
	}),

	createRouteModal({
		path: '/profile/auth/recover',
		component: () => import('./components/pages/profile/auth/web2/Recover.vue'),
	}),
	createRouteModal({
		path: '/profile/auth/password-reset',
		component: () => import('./components/pages/profile/auth/web2/PasswordReset.vue'),
		meta: {
			title: createPageTitle('Password reset')
		},
	}),

	createRouteModal({
		path: '/profile/auth/terms-agreement',
		component: () => import('./components/pages/profile/auth/TermsAgreement.vue'),
		meta: {
			title: createPageTitle('Terms Agreement'),
			showCloseButton: false,
		},
	}),

	createRoutePage({
		path: '/profile/settings',
		component: () => import('./components/pages/profile/Settings.vue'),
		meta: {
			title: createPageTitle('Settings')
		},
	}),
	createRouteModal({
		defaultPath: '/profile/settings',
		modalPath: '/profile/settings/password',
		component: () => import('./components/pages/profile/settings/Password.vue'),
	}),

	createRoutePage({
		path: '/profile/portfolio',
		component: () => import('./components/pages/profile/Portfolio.vue'),
		meta: {
			title: createPageTitle('Portfolio')
		},
	}),

	createRoutePage({
		path: '/profile/credits',
		component: () => import('./components/pages/profile/Credits.vue'),
		meta: {
			title: createPageTitle('Credits')
		},
	}),

	createRoutePage({
		path: '/profile/certificates',
		component: () => import('./components/pages/profile/Certificates.vue'),
		meta: {
			title: createPageTitle('Certificates')
		},
	}),

	createRoutePage({
		path: '/profile/referral',
		component: () => import('./components/pages/profile/Referral.vue'),
		meta: {
			title: createPageTitle('Invite a friend')
		},
	}),

	...[
		{
			components: {
				default: () => import('./components/pages/profile/Credits.vue'),
				intro: () => import('./components/pages/profile/credits/PurchaseIntroStep.vue'),
				checkStep: () => import('./components/pages/profile/credits/PurchaseCheckStep.vue'),
			},
			defaultPath: '/profile/credits',
			action: 'purchase',
			offerType: 'profile-credits',
			purchaseApiEndpoint: '/profile/credits',
		},
		{
			components: {
				default: () => import('./components/pages/profile/credits/payout/Start.vue'),
				intro: () => import('./components/pages/profile/credits/payout/PayoutIntroStep.vue'),
				checkStep: () => import('./components/pages/profile/credits/payout/PayoutCheckStep.vue'),
			},
			defaultPath: '/profile/credits',
			action: 'payout',
			offerType: 'profile-credits-payout',
			purchaseApiEndpoint: '/profile/credits',
		},
	].reduce((result, item) => {
		return [
			...result,
			...[
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/' + item.action,
					components: {
						default: item.components.default,
						dialog: item.components.intro,
					},
				}),

				// Address book
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/' + item.action + '/address-book/:type',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/addressBook/AddressBookChooser.vue'),
					},
				}),
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/' + item.action + '/address-book/:type/add',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/addressBook/AddressBookAdd.vue'),
					},
				}),
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/' + item.action + '/address-book/:type/edit/:id',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/addressBook/AddressBookEdit.vue'),
					},
				}),

				/*// Web2
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/web2/payment',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/web2/PaymentStep.vue'),
					},
				}),
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/web2/completed',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/web2/CompletedStep.vue'),
					},
				}),
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/web2/rejected',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/web2/RejectedStep.vue'),
					},
				}),

				// Web3
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/web3/payment',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/web3/PaymentStep.vue'),
					},
				}),
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/web3/approval',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/web3/ApprovalStep.vue'),
					},
				}),
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/web3/completed',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/web3/CompletedStep.vue'),
					},
				}),
				*/

				/*
				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/purchase/review',
					components: {
						default: item.components.default,
						dialog: () => import('./components/pages/purchase/ReviewStep.vue'),
					},
				}),
				*/

				createRouteModal({
					defaultPath: item.defaultPath,
					modalPath: item.defaultPath + '/' + item.action + '/check-payment',
					components: {
						default: item.components.default,
						dialog: item.components.checkStep,
					},
				}),

			].map(route => {
				return {
					...route,
					meta: {
						purchase: {
							offerType: item.offerType,
							purchaseApiEndpoint: item.purchaseApiEndpoint,
						},
					}
				}
			})
		];
	}, []),

	createRoutePage({
		path: '/test/blank',
		component: () => import('./components/pages/common/TestBlank.vue'),
	}),

	createRoutePage({
		path: '/:pathMatch(.*)*',
		component: () => import('./components/pages/common/NotFound.vue'),
		meta: {
			title: createPageTitle('404')
		},
	}),

];

export default routes;
