<template>

	<div>

		<Modal/>

		<router-view/>

	</div>

</template>

<script>

import Modal from "@/components/layout/Modal.vue";

export default {

	name: 'EmptyRouterView',

	components: {Modal}

}

</script>
