<template>

	<!-- When enabled / disabled, adjust it in app-web/src/config.ts! -->
	<div
		class="bg-ecogreen"
		data-testid="layout-TopBar-banner"
		v-if="!AuthManager.reactiveIsLoggedIn.value"
	>

		<div class="max-w-7xl mx-auto px-4 w-full flex justify-center">
			<div class="flex items-center gap-x-4 text-white py-2">
				<span>Register now and receive 3 free credits!</span>

				<router-link to="/profile/auth/register" class="bg-ecoheaderblue hover:bg-opacity-75 px-4 py-1 rounded-full" data-testid="button">
					Register
				</router-link>
			</div>
		</div>

	</div>

	<MobileNavigation :menu-items="menuItems"/>

	<DesktopNavigation class="hidden lg:block" :menu-items="menuItems"/>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Profile from './topBar/Profile.vue';
import DesktopNavigation from './topBar/DesktopNavigation.vue';
import MobileNavigation from './topBar/MobileNavigation.vue';
import MapSearch from './topBar/MapSearch.vue';
import Testing from "@/lib/Testing";
import AuthManager from "@/lib/AuthManager";

export default defineComponent({

	components: {MapSearch, DesktopNavigation, MobileNavigation, Profile},

	emits: [
		'height'
	],

	setup() {
		return {
			AuthManager,
		};
	},

	mounted() {
		this.$nextTick(() => {
			this.updateHeight();
			window.addEventListener('resize', this.updateHeight);
		});
	},

	methods: {
		updateHeight() {
			this.$emit('height', this.$el.nextElementSibling.clientHeight);
		}
	},

	computed: {

		menuItems(): Array<Object> {

			return [

				// @ugly Disabled temporarily by client request. Tests depend on it.
				{label: 'Enterprise', to: '/enterprise', active: Testing.isTesting},

				{label: 'Buy Land', to: '/map'},

				{label: 'Compensate now', to: '/compensate-now', active: Testing.isTesting},
				{
					label: 'Virtual land',
					to: '/virtual-land',
					subItems: [
						{label: 'Domains and plots', to: '/virtual-land/domains-plots'},
						{label: 'Rewards', to: '/virtual-land/rewards'},
						{label: 'Strategies', to: '/virtual-land/strategies'},
					]
				},
				{label: 'How it works', to: '/how-it-works'},
				{
					label: 'About us',
					to: '/about-us',
					subItems: [
						{label: 'Meet the team', to: '/team'},
						{label: 'FAQ', to: '/faq'},

						// @ugly Disabled temporarily by client request
						//{label: 'Roadmap', to: '/roadmap'},

						// @ugly Disabled temporarily by client request. Tests depend on it.
						//{label: 'Why Ecolands', to: '/why-ecolands'},

						//{label: 'Contact', to: '/contact'}
					]
				},
				// {label: 'Marketplace', to: import.meta.env.VITE_OPENSEA_COLLECTION_URL},
				// {label: 'Staking', to: '/staking'},
				// {label: 'Carbon', to: '/carbon'},

				{label: 'Contact', to: '/contact'},
			]
				.filter(item => item.active !== false);
		},
	},
});
</script>
